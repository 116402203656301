.banner {
    background-image: url('/public/foto_4.jpg');
    background-size: cover;
    background-position: center;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    margin: 1rem 0 4rem 0;
}

.banner-content {
    background-color: white;
    padding: 20px;
    color: var(--black);
    width: 50rem;
    border-radius: 1.8rem;
    padding: 6rem;
}

.banner-title{
    max-width: 20rem;
}

.banner-subtitle{
    max-width: 20rem;
}

.arrow-button{
    padding: 1rem 0.5rem 0.5rem 0.5rem !important;
}
