@import "global.css";

.roy-navbar{
    padding: 2rem 6rem 2rem 6rem !important;
    justify-content: space-between;
}

.nav-link{
    color: var(--black) !important;
    font-weight: 600 !important;
    padding: 0 1rem 0 1rem !important;
}

.nav-link:hover{
    color: var(--blue) !important;
}

.navbar-header {
    display: flex;
    align-items: center;
}

.navbar-header .navbar-toggler {
    margin-left: auto;
    margin-right: 1rem;
}

/* Media query for tablets */
@media (max-width: 950px) {
    .nav-link {
        margin-top: 1.5rem;
    }
}
/* Media query for small devices (e.g., phones) */
@media (max-width: 575.98px) {
    .roy-navbar{
        padding: 1rem !important;
    }
    .nav-link {
        margin-top: 1.5rem;
    }
}

/* Media query for extra small devices (e.g., very small phones) */
@media (max-width: 350px) {
    .nav-link {
        margin-top: 2rem;
    }
}