.pic-container{
    padding: 2rem;
}

.goal-pic{
    border-radius: 1.8rem;
    width: 30rem;
}

.goal-container{
    padding: 4rem;
}

.goal-title{
    font-size: 50px;
}

.goal-subtitle{
    font-size: 22px;
}