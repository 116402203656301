@import "./global.css";

.contact-banner {
    text-align: center;
    padding: 5rem;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.contact-container{
    width: 50rem !important;
    background-color: var(--dark-roy);
    padding: 4rem;
    color: white;
    border-radius: 1.8rem;
}
