
@import "global.css";

.services-page {
    padding: 4rem 2rem 4rem 2rem;
    margin-bottom: 20rem;
  }
  
  .service-card {
    text-align: center;
    transition: all 0.2s ease-in-out !important;
    border: none !important;
    border-radius: 1.4rem !important;
    overflow: hidden;
    background-color: var(--black_neutral) !important;
    padding: 2rem !important;
    min-height: 40rem !important;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: var(--black_80) !important;
  }
  
  .service-card-title {
    font-weight: bold;
    color: var(--gray) !important;
  }
  
  .service-card-text {
    color: white;
  }

  .service-card-img{
    border-radius: 1.1rem;
  }
  