@import "./global.css";

.roy-dark-button{
    background-color: var(--dark-roy) !important;
    padding: 1.5rem !important;
    border-radius: 1.3rem !important;
    border: none !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
}

.roy-dark-button:hover{
    background-color: var(--blue) !important;
}