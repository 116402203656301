@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root{
    /* Primary colors */
    --black: #011627;
    --blue: #00768A;
    --dark-roy: #292F36;
    --soft-green: #EFF4F3;

    /* Neutral colors */
    --black_neutral: #333333;
    --black_80: #4F4B46;
    --black_60: #858585;
    --black_30: #CCCCCC;
    --black_10: #EBEBEB;
    --gray: #F5F6F7;
    --white: #FFFFFF;
    --neutral-300: #EFF0F6;
}
