@import 'global.css';

.ceo-page {
  padding: 20px;
}

.ceo-page h2 {
  margin-top: 20px;
  font-size: 1.8em;
}

.ceo-page .card{
    border: none !important;
}

.ceo-image {
  width: 100%;
  border-radius: 1.5rem;
  transition: transform 0.3s ease-out;
  padding: 1rem;
}

.sticky-image-col {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.timeline {
  position: relative;
  max-width: 100%;
  margin: 20px 0;
}

.timeline-item {
  padding: 2rem;
  position: relative;
  border-radius: 1.3rem;
  width: 100%;
  margin-bottom: 20px;
}

.timeline-item:hover {
  transform: translateY(-10px);
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--blue);
  top: 20px;
  left: -8px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item:nth-child(odd)::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: var(--blue);
  top: 0;
  left: -2px;
  z-index: 0;
}

.timeline-item:nth-child(even)::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: var(--blue);
  top: 0;
  left: -2px;
  z-index: 0;
}

.timeline-content {
  padding-left: 20px;
  position: relative;
  z-index: 2;
}

.timeline-item[data-aos^="fade"]:nth-child(odd) .timeline-content {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}

.timeline-item[data-aos^="fade"]:nth-child(even) .timeline-content {
  padding-left: 20px;
  text-align: left;
}

.card {
  border: none;
  background: #f8f9fa;
  transition: background 0.3s ease;
}

.card:hover {
  background: #e9ecef;
}

.card-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1em;
  line-height: 1.6;
}

.card-footer {
  background-color: transparent;
  border-top: none;
  font-size: 0.9em;
  color: #6c757d;
}

ul.no-dots {
  list-style-type: none;
  padding-left: 0;
}

ul.no-dots li {
  margin-bottom: 10px;
}
