@import "./global.css";

.process-container {
    padding: 8rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.process {
    margin: 1rem;
    padding: 2rem !important;
    border-radius: 1.8rem;
    transition: all 0.3s;
    background-color: var(--dark-roy);
    flex: 1;
    max-width: 30%;
    min-width: 250px;
    color: white;
}

.process:hover {
    background-color: var(--blue);
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.process-title {
    font-size: 25px;
    text-align: center;
    margin-bottom: 1.5rem;
}

.process-subtitle {
    font-size: 18px;
    text-align: justify;  /* Justify text */
    line-height: 1.6;
}

/* Media query for tablets */
@media (max-width: 950px) {
    .process-container {
        padding: 4rem 2rem;
    }

    .process {
        margin: 1.5rem 1rem;
        max-width: 45%;
    }
}

/* Media query for tablets */
@media (max-width: 800px) {
    .process-container {
        padding: 2rem 1.5rem;
    }

    .process {
        max-width: 100%;
    }
}

/* Media query for small devices (e.g., phones) */
@media (max-width: 575.98px) {
    .process-container {
        padding: 2rem 1rem;
    }
}
