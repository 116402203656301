@import "./global.css";

.statistics-container{
    padding: 4rem;
    background-color: var(--soft-green);
    align-items: center;
    text-align: center;
    border-radius: 1.8rem;
    margin: 2rem;
}

.statistics-title{
    font-size: 50px;
    color: var(--blue);
    font-weight: 700;
}

.single-statistic-container{
    border-left: solid 0.05rem var(--blue);
    border-right: solid 0.05rem var(--blue);
}