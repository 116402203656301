.brands-carousel {
  overflow: hidden;
  position: relative;
  width: 100%;  /* Make the carousel width responsive */
  height: 8rem;  /* Set a specific height for the carousel */
  margin: 2rem 0;
}

.brands-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: scroll 35s linear infinite;
  white-space: nowrap;  /* Prevent wrapping to multiple rows */
}

.brand-col {
  flex: 0 0 auto;  /* Prevent columns from resizing */
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-icon {
  min-width: 8rem;  /* Set width to 8rem */
  height: auto;  /* Maintain aspect ratio */
  display: block;  /* Ensure the image is block-level */
  margin: 0 auto;  /* Center the image */
}

/* Pause the animation on hover */
.brands-carousel:hover .brands-container {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
